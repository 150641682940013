<template>
  <v-card v-bind="$attrs" class="pa-3">
    <div class="d-flex grow flex-wrap">
      <v-sheet
        v-if="title || icon || $slots.heading"
        :class="{
          'pa-4': !$slots.image,
        }"
        :color="color"
        :max-height="icon ? 90 : undefined"
        :width="icon ? 'auto' : '100%'"
        elevation="4"
        class="text-start base-panel-heading"
        dark
      >
        <slot v-if="$slots.heading" name="heading" />

        <slot v-else-if="$slots.image" name="image" />

        <div v-else>
          <v-icon v-if="icon" size="26" class="base-panel-icon" v-text="icon" />
          <span v-if="title" class="base-panel-title" v-text="title" />
        </div>

        <div v-if="text" class="headline font-weight-thin" v-text="text" />
      </v-sheet>

      <div v-if="$slots['after-heading']" class="ml-6">
        <slot name="after-heading" />
      </div>
    </div>

    <slot />

    <template v-if="$slots.actions">
      <v-divider class="mt-2" />

      <v-card-actions class="pb-0">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "Panel",

  props: {
    color: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: undefined,
    },
    image: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="sass">
.base-panel
  &-heading
    position: relative
    top: -30px
    transition: .3s ease
    z-index: 1
  &-title
    font-size: 1.3rem
    font-weight: 300
    padding-left: 5px
    top: 4px
  &-icon
    margin-top: -7px
</style>
