module.exports = {
  ...require("./base.json"),
  validation: require("./validation.json"),
  project: require("./project.json"),
  roles: require("./roles.json"),
  profile: require("./profile.json"),
  administrators: require("./administrators.json"),
  reset: require("./reset.json"),
  quiz: require("./quiz.json"),
  norm: require("./norm.json"),
  group: require("./group.json"),
  share: require("./share.json"),
  result: require("./result.json"),
  sets: require("./sets.json"),
};
