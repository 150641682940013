<template>
  <v-dialog v-model="showDialog" max-width="30vw" dismissible>
    <v-card>
      <v-card-title class="headline">{{ message.title }}</v-card-title>
      <v-card-text>
        <v-alert
          v-if="message"
          tile
          class="mb-0"
          :type="message.type"
          transition="scale-transition"
        >
          {{ message.text }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showDialog = false">
            {{ $t("Close") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
      message: {},
    };
  },
  mounted() {
    window.eventBus.$on("alert", (message) => {
      this.message = message;
      this.showDialog = true;
    });
  },
};
</script>
