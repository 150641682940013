import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/pages/auth/Login"),
    },
    {
      path: "/",
      component: () => import("@/components/layout/PageLayout"),
      redirect: '/quizzes',
      meta: {
        requiresAuth: true,
      },
      children: [
        // Pages
        {
          name: "profile.page title",
          path: "/user",
          component: () => import("@/pages/users/UserProfile"),
        },
        {
          name: "administrators.page title",
          path: "/admins",
          meta: {
            permission: "edit admins",
          },
          component: () => import("@/pages/admins/UsersTable"),
        },
        {
          name: "roles.page title",
          path: "/roles",
          meta: {
            permission: "edit roles",
          },
          component: () =>
            import("@/pages/admins/roles-permissions/RolesAndPermissions"),
        },
        {
          name: "quiz.page title",
          path: "/quizzes",
          component: () => import("@/pages/quizzes/Quizzes"),
        },
        {
          name: "sets.page title",
          path: "/sets",
          component: () => import("@/pages/sets/SetsTable"),
        },
        {
          name: "result.quiz results",
          path: "/results",
          component: () => import("@/pages/results/QuizResults"),
        },
        {
          name: "result.participant answers",
          path: "/results/answers/:id",
          component: () => import("@/pages/results/QuizAnswersTable"),
        },
        {
          name: "group.page title",
          path: "/clients",
          component: () => import("@/pages/clients/Clients"),
        },
        {
          name: "group.page title",
          path: "/clients/groups",
          component: () => import("@/pages/clients/GroupsTable"),
        },
        {
          name: "group.participant",
          path: "/clients/details/:id",
          component: () => import("@/pages/clients/ClientDetails"),
        },
        {
          name: "group.page title add",
          path: "/clients/groups/:id",
          component: () => import("@/pages/clients/CreateGroup.vue"),
        },
        {
          name: "quiz.page title add",
          path: "/quizzes/:id",
          component: () => import("@/pages/quizzes/CreateQuiz"),
        },
      ],
    },
  ],
});

function hasPermission(permissions, permission) {
  if (typeof permission === "string") {
    return permissions.includes(permission);
  } else {
    for (const i in permission) {
      if (permissions.includes(permission[i])) {
        return true;
      }
    }
    return false;
  }
}

router.beforeEach((to, from, next) => {
  let loggedInUser = localStorage.getItem("loggedInUser");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    if (!loggedInUser) {
      const loginpath = window.location.pathname;
      next({ name: "Login", query: { from: loginpath } });
    } else if (loggedInUser) {
      loggedInUser = JSON.parse(loggedInUser);
      if (
        to.meta.permission &&
        !hasPermission(loggedInUser.permissions, to.meta.permission)
      ) {
        next(from);
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
